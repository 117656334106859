<template>
  <v-card :to="item.route" class="dashcard">
    <div class="d-flex grow flex-wrap justify-space-around">
      <v-sheet
        elevation="6"
        dark
        :color="item.color || 'grey darken-1'"
        class="dashcard__sheet pa-3"
      >
        <v-badge
          :value="!!item.badge"
          :content="item.badge"
          :color="item.badgeColor || 'red darken-1'"
        >
          <v-icon size="32">{{ item.icon }}</v-icon>
        </v-badge>
      </v-sheet>
    </div>
    <v-card-text class="text-h5 font-weight-light text-center pt-0 pb-2">
      {{ item.title }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'DashboardCard',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
}

</script>

<style lang="scss">
$dashcard-icon-shift: 10px;

.dashcard {
  margin-top: $dashcard-icon-shift;

  &__sheet {
    border-radius: 5px;
    position: relative;
    top: -$dashcard-icon-shift;
    width: auto;
    min-width: 56px;
    min-height: 56px;
    max-height: 90px;
  }
}
</style>
