<template>
  <v-container>
    <v-row class="dashboard">
      <v-col cols="12" class="mb-1" order-sm="1">
        <v-card>
          <v-app-bar flat>
            <v-toolbar-title>Die nächsten Touren</v-toolbar-title>
            <v-spacer />
            <event-display-settings-dialog />
          </v-app-bar>
          <template v-if="nextEventsLoaded && nextEvents.length > 0">
            <event-expansion-panel
              :events="nextEvents"
              :month-headers="false"
            />
            <div class="pa-2">
              <v-btn
                text
                outlined
                block
                color="primary"
                :to="{
                  name: 'Events',
                  hash: `#evt${nextEvents[0].id}`,
                  params: { year: new Date().getFullYear() },
                }"
              >
                Mehr Touren Anzeigen
              </v-btn>
            </div>
          </template>
          <v-progress-linear
            v-else-if="!nextEventsLoaded"
            :indeterminate="true"
          />
          <v-card-text v-else>Keine Touren gefunden.</v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="6"
        sm="4"
        md="3"
        lg="2"
        v-for="item in dashitems"
        :key="item.title"
      >
        <dashboard-card :item="item" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'pinia'

import eventService from '@/services/event'
import navigationItems from '@/navigation'

import EventDisplaySettingsDialog from '@/components/events/EventDisplaySettingsDialog.vue'
import EventExpansionPanel from '@/components/events/EventExpansionPanel.vue'
import DashboardCard from '@/components/cards/DashboardCard.vue'
import { canNavigate } from '@/router/helpers'
import { useUserStore } from '@/stores/user'

export default {
  name: 'Dashboard',
  components: { EventExpansionPanel, EventDisplaySettingsDialog, DashboardCard },
  props: {},
  data() {
    return {
      nextEvents: [],
      nextEventsLoaded: false,
    }
  },
  computed: {
    dashitems() {
      return navigationItems.filter(i => i.dashboard && canNavigate(this.$router.resolve(i.route).route))
    },

    ...mapState(useUserStore, { eventFilter: store => store.settings.eventFilter }),
  },
  watch: {
    eventFilter: {
      handler(val) { this.loadEvents(val) },
      deep: true,
    },
  },
  mounted() {
    this.loadEvents(this.eventFilter)
  },
  methods: {
    goTo(route) {
      this.$router.push(route)
    },
    loadEvents(filters) {
      this.nextEventsLoaded = false
      eventService.list({ only: 'future', ...filters }, 5)
        .then(response => {
          this.nextEvents = response.data.items
          this.nextEventsLoaded = true
        })
    },
  },
}

</script>

